<template>
	<div>
		<el-dialog
			title="Movimmientos"
			:visible="showDialog"
			@close="close"
			@open="create"
			:close-on-click-modal="false"
			:close-on-press-escape="false"
			width="80%"
		>
			<div class="row">
				<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
					<!-- <emb-perfect-scrollbar style="height: 50vh;"> -->
						<div class="table-responsive">
							<v-simple-table dense>
								<template v-slot:default>
									<thead>
										<tr>
											<th class="text-center">N°</th>
											<th class="text-center">FECHA</th>
											<th class="text-center">
												OPERACIÓN
											</th>
											<th class="text-center">
												RESPONSABLE
											</th>
											<th class="text-center">MÉTODO P.</th>
											<th class="text-center">
												COMPROBANTE
											</th>
											<th class="text-center">NÚMERO</th>
											<th>REFERENCIA</th>
											<th class="text-center">INGRESO</th>
											<th class="text-center">EGRESO</th>
											<th class="text-right">SALDO</th>
											<th class="text-right">DETALLES</th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="(row, index) in records" :key="index">
											<td scope="row" class="text-center">
												{{ index + 1 }}
											</td>
											<td scope="row" class="text-center">
												{{ row.created_at }}
											</td>
											<td scope="row" class="text-center">
												{{ row.operation }}
											</td>
											<td scope="row" class="text-center">
												{{ row.user }}
											</td>
											<td scope="row" class="text-center">
												{{ row.payment_method_type }}
											</td>
											<td scope="row" class="text-center">
												{{ row.document_type }}
											</td>
											<td scope="row" class="text-center">
												{{ row.number }}
											</td>
											<td scope="row">
												{{ row.reference }}
											</td>
											<td class="text-right">
												<span class="text-success">{{
													row.type == 'income' ? row.amount : ''
												}}</span>
											</td>
											<td class="text-right">
												<span class="text-danger">{{
													row.type == 'expense' ? row.amount : ''
												}}</span>
											</td>
											<td class="text-right">
												<span>{{ row.balance }}</span>
											</td>
											<td class="text-right">
												<v-menu
													:close-on-content-click="false"
													:nudge-width="200"
													offset-x
													v-if="row.items.length > 0"
												>
													<template v-slot:activator="{ on, attrs }">
														<v-btn
															class="my-1"
															color="info"
															dark
															v-bind="attrs"
															v-on="on"
															fab
															x-small
														>
															<v-icon
																>mdi-format-list-bulleted</v-icon
															>
														</v-btn>
													</template>
													<v-card dark color="secondary">
														<v-row class="mr-1 ml-1">
															<v-col cols="12" xl="12" lg="12">
																<v-simple-table dense>
																	<template v-slot:default>
																		<thead>
																			<tr>
																				<th
																					class="text-right"
																				>
																					Cantidad
																				</th>
																				<th
																					class="text-left"
																				>
																					Producto
																				</th>
																				<th
																					class="text-right"
																				>
																					Precio
																				</th>
																			</tr>
																		</thead>
																		<tbody>
																			<tr
																				v-for="(item,
																				index) in row.items"
																				:key="index"
																			>
																				<td>
																					{{
																						item.quantity
																					}}
																				</td>
																				<td>
																					{{
																						item.item
																							.name
																					}}
																				</td>
																				<td>
																					{{
																						item.unit_price
																					}}
																				</td>
																			</tr>
																		</tbody>
																	</template>
																</v-simple-table>
															</v-col>
														</v-row>
													</v-card>
												</v-menu>
											</td>
										</tr>
									</tbody>
								</template>
							</v-simple-table>
						</div>
					<!-- </emb-perfect-scrollbar> -->
				</div>
				<div
					class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12"
				>	
					<v-card color="secondary">
						<div class="table-responsive">
							<v-simple-table dense>
								<template v-slot:default>
									<tbody>
										<tr>
											<td scope="row" class="text-right">Pagos con Tarjeta:</td>
											<td class="text-right">{{ totals.payment_card }}</td>
										</tr>
										<tr>
											<td scope="row" class="text-right">Pagos con Yape:</td>
											<td class="text-right">{{ totals.payment_yape }}</td>
										</tr>
										<tr>
											<td scope="row" class="text-right">Pagos con Plin:</td>
											<td class="text-right">{{ totals.payment_plin }}</td>
										</tr>
										<tr>
											<td scope="row" class="text-right">Pagos con Market:</td>
											<td class="text-right">{{ totals.payment_market }}</td>
										</tr>
									</tbody>
								</template>
							</v-simple-table>
						</div>

					</v-card>
				</div>
				<div
					class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12"
				>
					<v-card color="secondary">
						<div class="table-responsive">
							<v-simple-table dense>
								<template v-slot:default>
									<tbody>
										<tr>
											<td scope="row" class="text-right">Total ingresos:</td>
											<td class="text-right">{{ totals.total_income }}</td>
										</tr>
										<tr>
											<td scope="row" class="text-right">Total egresos:</td>
											<td class="text-right">{{ totals.total_expense }}</td>
										</tr>
										<tr>
											<td scope="row" class="text-right">Saldo final:</td>
											<td class="text-right">
												<strong>{{ totals.final_balance_frt }}</strong>
											</td>
										</tr>
									</tbody>
								</template>
							</v-simple-table>
						</div>
					</v-card>
				</div>
			</div>
			<div class="text-center mb-3 mt-3">
				<el-button type="danger" @click.prevent="close()">Cerrar</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
// import VuePerfectScrollbar from 'vue-perfect-scrollbar';

export default {
	props: ['showDialog', 'recordId'],
	data() {
		return {
			loading: false,
			titleDialog: null,
			resource: 'openings',
			records: [],
			totals: {
				total_income: '0.00',
				total_expense: '0.00',
				payment_card: '0.00',
				payment_plin: '0.00',
				payment_yape: '0.00',
				payment_market: '0.00',
			},
		};
	},
	// components: {
	// 	embPerfectScrollbar: VuePerfectScrollbar,
	// },
	methods: {
		async create() {
			await this.getRecords();
		},
		getRecords() {
			this.$http.get(`/${this.resource}/transactions/${this.recordId}`).then((response) => {
				this.totals = response.data.totals;
				this.records = response.data.records;
				this.load = true;
			});
		},
		close() {
			this.$emit('update:showDialog', false);
			this.records = [];
			this.totals = {};
		},
	},
};
</script>

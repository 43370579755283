<template>
	<div>
		<div class="d-flex justify-space-between align-center mb-2 mt-6">
			<div class="input-wrap">
				<h5 class="mb-2">Historial de caja</h5>
			</div>
			<div class="action-btn-wrap"></div>
		</div>
		<v-card color="secondary" class="pa-3">
			<emb-data-table :resource="resource" :columns="columns">
				<tr slot="heading">
					<th>N°</th>
					<th>CAJA</th>
					<th>RESPONSABLE</th>
					<th>FECHA APERTURA</th>
					<th>HORA APERTURA</th>
					<th>FECHA CIERRE</th>
					<th>HORA CIERRE</th>
					<th class="text-right">SALDO INICIAL</th>
					<th class="text-right">TOTAL INGRESOS</th>
					<th class="text-right">TOTAL EGRESOS</th>
					<th class="text-right">SALDO FINAL</th>
					<th class="text-right">ACCIONES</th>
				</tr>

				<tr></tr>
				<tr slot-scope="{ index, row }">
					<td>{{ index }}</td>
					<td>{{ row.cash }}</td>
					<td>{{ row.user }}</td>
					<td>{{ row.date_opening }}</td>
					<td>{{ row.time_opening }}</td>
					<td>{{ row.date_closed }}</td>
					<td>{{ row.time_closed }}</td>
					<td class="text-right">{{ row.beginning_balance }}</td>
					<td class="text-right">{{ row.income }}</td>
					<td class="text-right">{{ row.expense }}</td>
					<td class="text-right">
						<strong class="text-success font-bold">{{ row.final_balance }}</strong>
					</td>
					<td class="text-right">
						<v-btn color="accent" dark small @click="showDetails(row.id)">
							Ver detalles
						</v-btn>
					</td>
				</tr>
			</emb-data-table>
		</v-card>
		<show-details :showDialog.sync="showDialog" :recordId="recordId"></show-details>
	</div>
</template>

<script>
import showDetails from './Details';
export default {
	components: {
		showDetails,
	},
	data() {
		return {
			recordId: null,
			showDialog: false,
			resource: 'openings-history',
			columns: {
				date_opening: 'Fecha de apertura',
			},
		};
	},
	methods: {
		showDetails(recordId) {
			this.recordId = recordId;
			this.showDialog = true;
		},
	},
};
</script>
